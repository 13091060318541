import { Box, Heading } from "@chakra-ui/react"
import React from "react"
import PropTypes from "prop-types"

const Header = ({ title , titlePosition}) => {
  return (
    <Box pt={2} pb={[4,4,6,6]} id="header">
      {title &&
        <Box id="headerBox" textAlign={titlePosition} marginTop={0} ml={0}>
          <Heading id="headerBoxHeading" variant="Title" as="h1" fontSize={[40, 60, 80, 80]} paddingTop={2} marginTop={0}>            
            {title}
          </Heading>
        </Box>
      }
    </Box>

  )
}

Header.propTypes = {
  title: PropTypes.string,
  titlePosition: PropTypes.string
};

Header.defaultProps = {
  titlePosition: "left"
}

export default Header

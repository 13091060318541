import React from "react";
import { Box, Flex, Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import Sidebar from "./sidebar";
import Image from "gatsby-image"

const MenuItems = ({ children, to, slug }) => {
  return (
    <GatsbyLink to={`/${to === "index" ? "" : to}`}>
      <Link textDecoration={to === slug ? 'underline' : 'none'}>
        <Box fontStyle="italic" textTransform="uppercase" fontSize={22} mt={[1, 1, 1, 1]} mr={8} display="block" color="brandYellow.500">
          {children}
        </Box>

      </Link>
    </GatsbyLink>

  )
};


const Navbar = ({ bgImage, data, slug, ...rest }) => {
  const [show] = React.useState(data.showBurgerBarOnly);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingTop={6}
      color="brandYellow.500"
      {...rest}
    >
      <Sidebar bgImage={bgImage} 
        display={['block', 'block', 'block', show ? "block" : 'none']}
        navbarItems={data.navbarItems}
        logo={data.logo}>
      </Sidebar>
      <Box
        display={["none", "none", "none", !show ? "flex" : "none"]}
        width={["full", "full", "full", "auto"]}
        alignItems="center"
        flexGrow={1}
      >
        <GatsbyLink to="/">
          <Box height="50px" width="50px" marginRight={5}>
            <Image fluid={data.logo.fluid} />
          </Box>
        </GatsbyLink>
        {data.navbarItems.map((item, index) => (
          <MenuItems slug={slug} to={item.linkTo.slug} key={index}>{item.linkTo.title}</MenuItems>
        ))}
      </Box>
    </Flex>
  );
};

export default Navbar;
